<!--
 * @Author: your name
 * @Date: 2021-04-28 13:26:48
 * @LastEditTime: 2021-05-28 15:46:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/TeamWork.vue
-->
<template>
	<div class="teamwork">
		<Header :baseInfo="baseInfo"/>
		<div class="swiper_banner" v-if="banner.length > 0" @mouseenter="on_swiper_enter" @mouseleave="on_swiper_leave">
			<swiper ref="mySwiperBanner" :options="swiperBanner" v-if="banner.length > 1">
				<swiper-slide v-for="(item , index) in banner" :key="index">
					<img style="width:100%;height:100%;" :src="item.pic" alt="">
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<img  @click="adDetail(banner[0].url)" style="width:100%;" v-else :src="banner[0].pic" alt="">
		</div>
		<div class="width_1200 margin_auto">
			<div class="d_flex flex_wrap img_items">
				<div @click="adDetail(item.url)" class="img_item" :style="index % 3 === 0 ? 'margin-left:0' : '' " v-for="(item , index) in teamworkLists" :key="index">
					<el-image :lazy="true" :src="item.pic"></el-image>
					<p>{{item.title}}</p>
				</div>
			</div>
			<div class="pagination" v-if="teamRequestPage.count > 0">
				<el-pagination
					background
					layout="prev, pager, next"
					:page-size="teamRequestPage.pageSize"
					:total="teamRequestPage.count"
					@current-change="teamChange"
					>
				</el-pagination>
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League :baseInfo="baseInfo" @openPop="openPop()"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>

<script>
import {config} from '../config';
import mixin from '../utils/mixin';
export default {
	mixins:[mixin],
	data () {
		return {
			baseImgurl:config.baseImgUrl,
		};
	},
	components:{
		"Form":()=>import('../components/Form')
	},

	created(){},

   	methods: {
		
	}
}

</script>
<style lang='scss' scoped>
	.teamwork{
		.teamwork_banner{
			width:100%;
			display: block;
		}
		.img_items{
			padding:50px 0;
			.img_item{
				width:370px;
				height:280px;
				overflow: hidden;
				position: relative;
				margin-top:50px;
				margin-left: 45px;
				.el-image{
					width:100%;
					height:100%;
					transition: all 1s ease 0s;
					transform: scale(1);
				}
				p{
					position: absolute;
					left:0;
					bottom:0;
					text-align: center;
					line-height: 70px;
					font-size:18px;
					color:#fff;
					background: rgba(0,0,0,.7);
					width:100%;
				}
			}
			.img_item:hover{
				.el-image{
					transform: scale(1.1);
				}
			}
		}
		.pagination{
			margin-bottom:40px;
		}
		::v-deep{
		.el-pagination.is-background .btn-next,
		.el-pagination.is-background .btn-prev,
		.el-pagination.is-background .el-pager li {
			margin: 0 5px;
			background-color: #fff;
			border:1px solid #999;
			color: #999;
			min-width: 30px;
			border-radius: 2px;
		}
		.el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #B91829;
			color: #FFF;
			border:1px solid #B91829;
		}
		.el-pagination.is-background .el-pager li:hover{
			color:#B91829;
		}
	}
	}
</style>